<template>
  <div v-if="hasViewPermission">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-print="false" />
    <div class="columns is-gapless">
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :is-loading="isTableLoading"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              :total-rows="totalRows"
              @pageChanged="onPageChange"
              @sort="sort">

              <tr v-for="(record, index) in records"
                :key="index">
                <td>{{ record.email }}</td>
                <td>{{ record.username }}</td>
                <td>{{ record.requestAt ? $filters.formatDateTimeLocale(`${record.requestAt}Z`, $userInfo.locale) : '' }}</td>
                <td>{{ record.resetAt ? $filters.formatDateTimeLocale(`${record.resetAt}Z`, $userInfo.locale) : '' }}</td>
              </tr>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="is-flex is-justify-content-center pt-5">
      <div class="notification is-danger is-flex is-size-4">
        You do not have permission to view this page.
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import BulmaTable from '@/components/BulmaTable'
import { AuthService } from '@/services'
import { ForgotPasswordListColumns } from './columns'

export default {
  name: 'ForgotPasswordListView',
  components: {
    AppDetailHeader,
    BulmaTable
  },
  data() {
    return {
      filter: {
        sortColumn: ForgotPasswordListColumns[0].name,
        sortOrder: ForgotPasswordListColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 30
      },
      isTableLoading: false,
      records: null,
      totalRows: 0
    }
  },
  computed: {
    columns() {
      return ForgotPasswordListColumns
    },

    filterKey() {
      return this.$userInfo ? `${this.$userInfo.sessionId}|${this.$route.meta.fkey}` : ''
    },

    hasViewPermission() {
      return this.$userInfo.isSupportUser || this.$userInfo.isCustomerAdministrator
    }
  },
  async created() {
    await this.getForgotPasswordRecords()
  },
  methods: {
    filterList() {
      this.persistFilter()
      this.getForgotPasswordRecords()
    },

    async getForgotPasswordRecords() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.records = await AuthService.getForgotPasswordLogRecords(this.filter)
      this.totalRows = this.records.length > 0 ? this.records[0].totalRows : 0
      this.isTableLoading = false
    },

    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getForgotPasswordRecords()
    },

    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },

    resetFilter() {
      this.filter.sortColumn = ForgotPasswordListColumns[1].name
      this.filter.sortOrder = ForgotPasswordListColumns[1].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 30
      this.persistFilter()
      this.getQuoteTemplates()
    },

    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter.sortColumn = filter.sortColumn
        this.filter.sortOrder = filter.sortOrder
        this.filter.pageIndex = filter.pageIndex
        this.filter.pageSize = filter.pageSize
      }
    },

    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getForgotPasswordRecords()
    }
  }
}
</script>
